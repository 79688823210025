.banner-img {
    width: 100%;
    height: 360px;
    object-fit: cover;
}

// .category__heading {
//     display: flex;
//     position: absolute;
//     width: 100%;
//     top: 40px;
//     z-index: 11;
//     line-height: 40px;
//     align-items: center;
//     font-size: 15px;
//     font-weight: 400;
//     list-style: none;
//     background: white;
//     color: #333;
//     .category__icon-heading {
//         position: relative;
//         top: -2px;
//         margin-left: 15px;
//         margin-right: 10px;
//         width: 20px;
//         font-size: 20px;
//     }
//     &:hover {
//         color: var(--primary-link);
//         background: #f5f5f5;
//     }
// }

// .category__list {
//     width: 100%;
//     height: 320px;
//     margin: 0;
//     position: absolute;
//     top: 40px;
//     background: white;
//     z-index: 11;
//     .category__item {
//         position: relative;
//         display: flex;
//         margin: 0;
//         line-height: 40px;
//         align-items: center;
//         font-size: 15px;
//         font-weight: 400;
//         list-style: none;
//         background: white;
//         color: #333;
//         &:hover {
//             background: #f5f5f5;
//             color: var(--primary-link);
//             transition: .3s ease-out;
    
//             .category-icon_open {
//                 opacity: 1;
//                 color: #e29396;
//             }
//         }
//     }
//     .category__icon {
//         position: relative;
//         top: -1px;
//         width: 20px;
//         margin: 0 14px;
//         font-size: 20px;
//     }
//     .category__icon-open {
//         // opacity: 0;
//         position: absolute;
//         float: right;
//         top: 0;
//         right: 5px;
//         font-size: 12px;
//         color: #e29396;
//     }

//     .category__sub-menu {
//         display: none;
//         position: absolute;
//         top: -40px;
//         left: 100%;
//         background-color: white;
//         min-width: 160px;
//         min-height: 360px;
//         box-shadow: 0px 10px 25px rgb(0 0 0 / 10%);
//         z-index: 1;

//         .category__item-sub {
//             width: 160px;
//             height: 100%;
//             padding: 0 15px;
//             line-height: 40px;
//             align-items: center;
//             text-decoration: none;
//             font-size: 15px;
//             font-weight: 5  00;
//             list-style: none;
//             background: white;
//             color: #333;
            
//             &:hover {
//                 color: var(--primary-link);
//                 background: #f5f5f5;
//             }
//         }
//     }
//     &:hover {
//         .category__sub-menu {
//             display: block;
//         }
//     }
    
    
// }
.category__list{
    .list__item {
        // display: flex;
        // width: 100%;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        color: #333;
        &:hover {
            background: #f5f5f5;
            color: var(--primary-link) !important;
            .category__icon-open {
                display: block;
            }
        }
    }
    .category__icon {
        width: 21px;
        margin: 0 10px;
        font-size: 15px;
    }
    .category__icon-open {
        display: none;
        position: relative;
        // right: -175px;
        float: right;
        font-size: 20px;
        color: #e29396;
    }
   .category__sub-menu {
        display: none;
        position: absolute;
        top: 0;
        left: 280px;
        height: 100%;
        background: white;
        box-shadow: 0px 10px 25px rgb(0 0 0 / 10%);
        color: #333;
        font-size: 15px;
   }
   &:hover {
        .category__sub-menu {
            display: block;
        }
   }
}
.link-title {
    line-height: 20px;
    margin: 0 auto;
    padding-left: 18px;
    // font-size: 15px;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow: hidden;
    text-transform: uppercase;
    text-decoration: none;
}




.owl-carousel.owl-loaded {
    display: block;
    height: 100%;
}

.owl-dots {
    position: absolute;
    bottom: 0;
    right: 46%;
}

.owl-theme .owl-dots .owl-dot span {
    background: #869791;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #fff;
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    10%,
    20% {
        -webkit-transform: scale(.9) rotate(-8deg);
        -ms-transform: scale(.9) rotate(-8deg);
        transform: scale(.9) rotate(-8deg);
    }

    30%,
    50%,
    70% {
        -webkit-transform: scale(1.3) rotate(8deg);
        -ms-transform: scale(1.3) rotate(8deg);
        transform: scale(1.3) rotate(8deg);
    }

    40%,
    60% {
        -webkit-transform: scale(1.3) rotate(-8deg);
        -ms-transform: scale(1.3) rotate(-8deg);
        transform: scale(1.3) rotate(-8deg);
    }

    100%,
    80% {
        -webkit-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}