.dropdown {
    position: relative;
    display: inline-block;
    &:hover {
        .dropdown-content {
            display: block;
        }
    }
    .dropdown-content {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
}


.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}
