.header-search {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: white;
    border-radius: 3px;
}

.header__search-select {
    display: flex;
    width: 170px;
    padding-left: 15px;
    color: #333333;

    span {
    font-size: 15px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    }

    .select-icon {
    line-height: 40px;
    height: 40px;
    color: #333;
    font-size: 15px;
    font-weight: bold;
    border-right: 1px solid #e2e2e2;
    padding-right: 5px;
    }

    .header-category {
        opacity: 0;
        position: absolute;
        list-style-type: none;
        background:white;
        color:#333;
        left: -16px;
        top: 40px;
        width: 170px;
        padding: 5px 10px;
        border: 1px solid #e2e2e2;
        box-shadow: 0 0 6px 0 #c3c3c3;
        transform: scaleY(0);
        opacity: 0;
        transition: .4s cubic-bezier(0.68, 0.18, 0.17, 1);
        transform-origin: top;
        z-index: 69;
        .header-category-item {
            padding: 2px 0;
            color: #333;
            text-decoration: none;
            transition: .3s ease-out;
            &:hover {
                color: var(--primary-link);
            }
        }
    }

    &:hover {
        .header-category {
            opacity: 1;
            transform: scaleY(1);
            transition: .10s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }
}

.header__search-input {
    position: relative;
    width: 316px;
    font-size: 15px;
    margin-left: 3px;
    input {
        width: 100%;
        border: none;
        
    }

    .search-icon {
        width: 55px;
        height: 35px;
        position: absolute;
        color: #ffffff;
        background: #e5101d;
        right: 2px;
        padding: 0 19px;
        top: -6px;
    }
}

.header-icon {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.icon-link {
    text-decoration: none;
        &:hover {
            .icon-text {
                color: var(--primary-link);
            }
        }
}

.icon-text {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 6px;
    line-height: 15px;
    font-size: 13px;
    color: white;
    transition: .3s ease-out;
    span {
    font-size: 15px;
        font-weight: bold;
    }
}

.user__icon {
    .user__menu {
        position: absolute;
        top: 66px;
        right: 10px;
        padding: 10px 0;
        display: none;
        border-radius: 3px;
        background: white;
        color: black;
        z-index: 10;
        ul {
            list-style: none;
            li {
                padding: 5px 25px;
                &:hover {
                    background: #f5f5f5;
                    color: var(--primary-link);
                }
            }
        }
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 30px;
        z-index: 10;
    }
    &:hover {
        .user__menu {
            display: block;
        }
    }
}




