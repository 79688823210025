.heading-icon {
    position: relative;
    top: 3px;
}
.product__item {
    padding: 10px 12px;
    background-color: white;
    // .product__item-wrap {
    //     height: 200px;
    // }
    .product__item-img {
        // width: 150px;
        // max-height: 200px;
        padding: 24px;
        object-fit: cover;
        transition: .9s ease-in-out;
        &:hover {
            transform: scale(1.1)
        }
    }
    .product__tag-discount {
        background-color: white;
        color: #ed1c24;
        position: absolute;
        border: 1px solid #ed1c24;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        right: 10px;
        top: 10px;
        font-size: 12px;
        font-weight: 600;
        z-index: 3;
    }
    .product__tag-size {
        font-size: 12px;
        color: #3a3a3a;
        border: 1px solid #d1d5db;
        padding: 1px 5px;
        border-radius: 5px;
        margin-right: 10px;
    }
    .product__tag-storage {
        font-size: 12px;
        color: #3a3a3a;
        border: 1px solid #d1d5db;
        padding: 1px 5px;
        border-radius: 5px;
    }
    .product__name-label {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        height: 40px;
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 10px;
        color: #222;
    }
    .product__price-cost {
        font-size: 15px;
        color: var(--primary-link);
    }
    .product__price-discount {
        margin-left: 10px;
        font-size: 14px;
        text-decoration: line-through;
        color: #707070;
    }
}

.owl-carousel .owl-item {
    position: relative;
    /* min-height: 1px; */
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}